/**
 * Given lightbox conditions figure out which lightbox to show
 */
import { useState, useEffect, useRef } from 'react'

const isUrlMatch = (currentPath, pathConfig) => {
  if (pathConfig.type === 'exact') {
    return pathConfig.path === currentPath
  }
  return currentPath.includes(pathConfig.path)
}

const cleanupDeprecatedStorageKeys = () => {
  const priorStorageKey = 'dojomojo_signup_form_viewed' // Can remove at later date

  try {
    // If user was tagged with the priorStorageKey, removes old flag
    const existingStorageKey = JSON.parse(localStorage.getItem(priorStorageKey))
    if (existingStorageKey) localStorage.removeItem(priorStorageKey)
  } catch (e) {
    console.error('Unable to access localStorage: ', e)
  }
}

const shouldDisplayBasedOnLocalStorage = conditions => {
  if (conditions.type === 'embed') return true

  const storageKey = `dojomojo_signup_form_${conditions.id}`

  try {
    cleanupDeprecatedStorageKeys()

    const displaySettings = JSON.parse(localStorage.getItem(storageKey))

    // If no display options have been set, should display Signup Form
    if (!displaySettings) return true

    // If displaySettings.hide exists without an expiration,
    // don't display Signup Form again for that user
    if (displaySettings.hide && !displaySettings.expiration) {
      return false
    }

    // If display expiration has been set, check whether it has expired
    if (displaySettings.hide && displaySettings.expiration) {
      const date = new Date()
      const expirationDate = new Date(displaySettings.expiration)
      const isExpired = date.getTime() > expirationDate.getTime()
      if (isExpired) {
        localStorage.removeItem(storageKey)
        return true
      }
      return false
    }

    return false
  } catch (e) {
    console.error('Unable to access localStorage: ', e)
    return true
  }
}

class Lightbox {
  constructor(id, conditions, handleShowLightbox) {
    this.id = id
    this.conditions = conditions
    this.handleShowLightbox = handleShowLightbox
    this.isUrlTargetMatch = false
    this.delayTimer = null
    this.isUserExiting = false
  }

  evaluateStatus() {
    const { type } = this.conditions
    if (type === 'immediately') {
      if (this.isUrlTargetMatch) {
        this.showLightbox()
      }
    } else if (type === 'delay') {
      // if url matches and there is no timer then create timer
      // if url doesn't match and there is an active timer then disable timer
      if (this.isUrlTargetMatch && !this.delayTimer) {
        this.delayTimer = setTimeout(() => {
          this.showLightbox()
        }, this.conditions.delay * 1000)
      } else if (!this.isUrlTargetMatch && this.delayTimer) {
        clearTimeout(this.delayTimer)
        this.delayTimer = null
      }
    } else if (type === 'exit' && this.isUserExiting) {
      if (this.isUrlTargetMatch) {
        this.showLightbox()
      }
    }
  }

  showLightbox() {
    const shouldDisplay = shouldDisplayBasedOnLocalStorage(this.conditions)
    if (shouldDisplay) this.handleShowLightbox(this.id)
  }

  checkUrlTargeting(currentPath) {
    const { allowListUrls, denyListUrls } = this.conditions
    // url doesn't matter
    if (allowListUrls.length === 0 && denyListUrls.length === 0) {
      this.isUrlTargetMatch = true
      this.evaluateStatus()
    }
    if (
      allowListUrls.length &&
      allowListUrls.find(pathConfig => isUrlMatch(currentPath, pathConfig))
    ) {
      this.isUrlTargetMatch = true
      this.evaluateStatus()
    }
    if (
      denyListUrls.length &&
      denyListUrls.find(pathConfig => !isUrlMatch(currentPath, pathConfig))
    ) {
      this.isUrlTargetMatch = true
      this.evaluateStatus()
    }
  }

  setIsUserExiting(isUserExiting) {
    this.isUserExiting = isUserExiting
    this.evaluateStatus()
  }

  cleanUp() {
    if (this.delayTimer) {
      clearInterval(this.delayTimer)
    }
  }
}

export default conditions => {
  const [triggeredLightboxIds, setTriggeredLightboxIds] = useState([])
  const lightboxes = useRef([])
  const checkUrlInterval = useRef(null)
  const currentUrl = useRef({ host: null, path: null })
  const checkExitEvent = useRef(null)

  const handleShowLightbox = lightboxId => {
    // lightboxes.current.forEach(lightbox => lightbox.cleanUp())
    clearInterval(checkUrlInterval.current)
    setTriggeredLightboxIds(prevState => {
      const lightboxIds = prevState.slice()
      lightboxIds.push(lightboxId)
      return lightboxIds
    })
  }

  // all of the event watchers
  const initEventWatchers = () => {
    // check every second to see if the url has changed
    const checkForUrlChanges = () => {
      const fullPath = `${window.location.pathname}${window.location.hash}`
      if (currentUrl.current.path !== fullPath) {
        currentUrl.current.path = fullPath
        currentUrl.current.host = window.location.host
        lightboxes.current.forEach(lightbox =>
          lightbox.checkUrlTargeting(currentUrl.current.path),
        )
      }
    }
    checkForUrlChanges()
    checkUrlInterval.current = setInterval(checkForUrlChanges, 1000)
    // look for the user exiting the page
    checkExitEvent.current = function mouseMoveListener(e) {
      if (e.clientY < 10) {
        lightboxes.current.forEach(lightbox => lightbox.setIsUserExiting(true))
        window.removeEventListener('mousemove', checkExitEvent.current)
      }
    }
    window.addEventListener('mousemove', checkExitEvent.current)
  }

  // init lightboxes
  useEffect(() => {
    if (!conditions || lightboxes.current.length) return
    lightboxes.current = Object.keys(conditions).map(
      lightboxId =>
        new Lightbox(lightboxId, conditions[lightboxId], handleShowLightbox),
    )
    initEventWatchers()
  }, [conditions])

  return {
    triggeredLightboxIds,
  }
}

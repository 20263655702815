import { useState, useEffect } from 'react'

const useSignupFormType = settings => {
  const initialState = {
    formType: '',
    isPopup: false,
    isEmbed: false,
  }

  const [formTypeMap, setFormTypeMap] = useState(initialState)

  useEffect(() => {
    if (!settings) return

    const formType = settings.type || 'popup'
    const isPopup = formType === 'popup' || formType === 'lightbox'
    const isEmbed = formType === 'embed'

    setFormTypeMap({ formType, isPopup, isEmbed })
  }, [settings])

  return formTypeMap
}

export default useSignupFormType

import React from 'react'
import Button from 'ui/Button'
import cn from 'classnames'
import Editable from 'components/Editable'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  button: {
    padding: '12px 15px !important', // overrides padding waterfall from .ql-editor in Lightbox/index.js
    textTransform: 'none',
    textAlign: 'none',
    fontSize: '14px',
    margin: '0 0 8px ',
    transition: 'opacity .2s',
    '& p': {
      margin: 0,
    },
    '&:hover': {
      opacity: ({ isEditMode }) => (isEditMode ? 1 : '65%'),
    },
  },
  primaryButton: {
    borderRadius: ({ settings }) =>
      `${settings.primaryButton.borderRadius || 2}px`,
  },
  secondaryButton: {
    borderRadius: ({ settings }) =>
      `${settings.secondaryButton.borderRadius || 2}px`,
  },
  editButton: {
    padding: 0,
  },
  realButton: {
    '& .ql-editor *': {
      cursor: 'pointer',
    },
  },
  primaryButtonFilled: {
    background: ({ settings }) => settings.primaryButton.color,
    '&:hover': {
      background: ({ settings }) => settings.primaryButton.color,
    },
  },
  primaryButtonOutline: {
    border: ({ settings }) => `1px solid ${settings.primaryButton.color}`,
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  secondaryButtonOutline: {
    border: ({ settings }) => `1px solid ${settings.secondaryButton.color}`,
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  secondaryButtonTextLink: {
    border: 'none',
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  // reset the material ui label so that it would look like the user defined one
  buttonLabel: {
    display: 'initial',
    alignItems: 'initial',
    justifyContent: 'initial',
    fontWeight: 'initial',
  },
})

const FormButtons = props => {
  const {
    handleSubmit,
    handleClose,
    handleContentUpdate,
    settings,
    page,
    isEditMode,
  } = props

  const css = useStyles({ settings, isEditMode })

  const renderSubmitButton = () => (
    <Button
      onClick={handleSubmit}
      inputProps={{
        classes: {
          root: cn({
            [css.button]: true,
            [css.realButton]: true,
            [css.primaryButton]: true,
            [css.primaryButtonFilled]:
              settings.primaryButton.design === 'filled',
            [css.primaryButtonOutline]:
              settings.primaryButton.design === 'outline',
          }),
          label: css.buttonLabel,
        },
      }}
    >
      <div className="ql-editor">
        <span
          dangerouslySetInnerHTML={{
            __html: settings.pages[page].primaryButton.content,
          }}
        />
      </div>
    </Button>
  )

  const renderEditableSubmitButton = () => (
    <div
      className={cn({
        [css.button]: true,
        [css.primaryButton]: true,
        [css.editButton]: true,
        [css.primaryButtonFilled]: settings.primaryButton.design === 'filled',
        [css.primaryButtonOutline]: settings.primaryButton.design === 'outline',
      })}
    >
      <Editable
        value={settings.pages[page].primaryButton.content}
        handleChange={handleContentUpdate('primaryButton', page)}
      />
    </div>
  )

  const renderCloseButton = () => (
    <Button
      onClick={handleClose}
      inputProps={{
        classes: {
          root: cn({
            [css.button]: true,
            [css.secondaryButton]: true,
            [css.realButton]: true,
            [css.secondaryButtonOutline]:
              settings.secondaryButton.design === 'outline',
            [css.secondaryButtonTextLink]:
              settings.secondaryButton.design === 'text link',
          }),
          label: css.buttonLabel,
        },
      }}
    >
      <div className="ql-editor">
        <span
          dangerouslySetInnerHTML={{
            __html: settings.pages[page].secondaryButton.content,
          }}
        />
      </div>
    </Button>
  )

  const renderEditableCloseButton = () => (
    <div
      className={cn({
        [css.button]: true,
        [css.secondaryButton]: true,
        [css.editButton]: true,
        [css.secondaryButtonOutline]:
          settings.secondaryButton.design === 'outline',
        [css.secondaryButtonTextLink]:
          settings.secondaryButton.design === 'text link',
      })}
    >
      <Editable
        value={settings.pages[page].secondaryButton.content}
        handleChange={handleContentUpdate('secondaryButton', page)}
      />
    </div>
  )

  const notThankYouPage = page < settings.pages.length - 1
  const { primaryButton, secondaryButton } = settings.pages[page]

  const renderedPrimaryButton = isEditMode
    ? renderEditableSubmitButton()
    : renderSubmitButton()
    
  const renderedSecondaryButton = isEditMode
    ? renderEditableCloseButton()
    : renderCloseButton()

  return (
    <React.Fragment>
      {primaryButton.isViewable && renderedPrimaryButton}
      {/* no close button on the last page */}
      {notThankYouPage && secondaryButton.isViewable && renderedSecondaryButton}
    </React.Fragment>
  )
}

FormButtons.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleContentUpdate: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  isEditMode: PropTypes.bool.isRequired,
}

export default FormButtons

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import FormButtons from '../FormButtons'
import TemplateInput from './TemplateInput'

const useStyles = makeStyles({
  container: {},
})

const Form = function (props) {
  const {
    settings,
    page,
    handleFormUpdate,
    handleSubmit,
    handleClose,
    formErrors,
    handleContentUpdate,
    isEditMode,
    handleBlur,
  } = props

  const css = useStyles(settings)

  // TODO: Add input.id here when included in settings
  const renderInput = (input, index) => (
    <div key={`${input.label}${index}`}>
      <TemplateInput
        inputSettings={input}
        themeColors={settings.themeColors}
        handleUpdate={handleFormUpdate(input.label)}
        errorMessage={formErrors[input.label] || null}
        primaryButtonColor={settings.primaryButton.color}
        fieldSettings={settings.fields}
        onBlur={handleBlur}
      />
    </div>
  )

  return (
    <div className={css.container}>
      {settings.pages[page].inputs.map(renderInput)}
      <FormButtons
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        handleContentUpdate={handleContentUpdate}
        settings={settings}
        page={page}
        isEditMode={isEditMode}
      />
    </div>
  )
}

Form.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  isCurrentPage: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  handleFormUpdate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  handleContentUpdate: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
}

export default Form

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Editable from 'components/Editable'
import LegalLanguage from 'components/Lightbox/Lightbox/LegalLanguage'

import Form from 'components/Lightbox/Form'
import ErrorScreen from 'components/Lightbox/ErrorScreen'

const useStyles = makeStyles({
  brandLogo: {
    height: ({ settings }) => settings.brandLogo.size,
    backgroundSize: 'contain',
    backgroundImage: ({ settings }) =>
      `url('${settings.brandLogo.url}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  description: {
    marginBottom: 32,
  },
})

const Page = ({
  settings,
  formState,
  pageIndex,
  isCurrentPage = false,
  isErrorScreen,
  handleClose,
  handleContentUpdate,
}) => {
  const css = useStyles({ settings })

  const isFinalPage = pageIndex === settings.pages.length

  // Use this to show any other page that's not in the form
  if (isFinalPage) {
    if (isErrorScreen) return <ErrorScreen />
  }

  return (
    <>
      {!!settings.brandLogo.url && <div className={css.brandLogo} />}
      <div className={css.description}>
        {settings.pages[pageIndex].title.isViewable && (
          <Editable
            value={settings.pages[pageIndex].title.content}
            handleChange={handleContentUpdate('title', pageIndex)}
            isHoverActiveOnLoad
          />
        )}
        {settings.pages[pageIndex].subtitle.isViewable && (
          <Editable
            value={settings.pages[pageIndex].subtitle.content}
            handleChange={handleContentUpdate('subtitle', pageIndex)}
          />
        )}
      </div>

      <LegalLanguage settings={settings} pageIndex={pageIndex} />

      <Form
        handleClose={handleClose}
        page={pageIndex}
        formState={formState}
        isCurrentPage={isCurrentPage}
      />
    </>
  )
}

Page.propTypes = {
  settings: PropTypes.object.isRequired,
  formState: PropTypes.array.isRequired,
  pageIndex: PropTypes.number.isRequired,
  isCurrentPage: PropTypes.bool.isRequired,
  isErrorScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleContentUpdate: PropTypes.func.isRequired,
}

export default Page

import React from 'react'
import ButtonInput from '@material-ui/core/Button'
import PropTypes from 'prop-types'

const Button = function (props) {
  const { children, fullWidth, inputProps, onClick } = props

  return (
    <ButtonInput
      variant="contained"
      onClick={onClick}
      disableElevation={true}
      fullWidth={fullWidth}
      classes={inputProps.classes}
    >
      {children}
    </ButtonInput>
  )
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
  onClick: PropTypes.func.isRequired,
}

Button.defaultProps = {
  fullWidth: true,
  inputProps: {},
}

export default Button

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'
import Text, { Dropdown, DatePicker } from 'ui/Input'
import usStates from './usStates'

const getBorderColor = (themeColors, isError) =>
  isError ? '#FF0000' : themeColors.inputBorder

const useStyles = makeStyles({
  inputContainer: {
    marginBottom: 8,
    '& .dojo-MuiOutlinedInput-notchedOutline': {
      border: 'none',
    }
  },
  rootInput: {
    border: 'none',
    background: ({ themeColors }) => `${themeColors.inputBackground}`,
    color: ({ themeColors }) => `${themeColors.inputText}`,
  },
  outline: {
    border: 'none',
    background: 'none',
  },
  input: {
    fontSize: '14px',
    margin: '0',
    padding: '16px 14px',
    borderRadius: ({ fieldSettings }) => `${fieldSettings?.borderRadius || 4}px !important`,
    boxSizing: 'content-box',
    border: ({ themeColors, isError }) => `1px solid ${getBorderColor(themeColors, isError)}`,
    '&::placeholder': {
      opacity: 100,
      color: ({ themeColors }) => `${themeColors.inputPlaceholder}`,
    },
    '&:hover': {
      border: ({ themeColors, isError }) =>
        `1px solid ${getBorderColor(themeColors, isError)}`,
    },
    '&:focus': {
      border: ({ primaryButtonColor }) => `1px solid ${primaryButtonColor}`,
    },
  },
  dropdownPlaceholder: {
    color: ({ themeColors }) => `${themeColors.inputPlaceholder}`,
  },
  dropdownSelect: {
    '&:focus': {
      background: '#ffffff',
    },
  },
})

const getTemplateProps = label => {
  switch (label) {
    case 'Phone':
      return { type: 'tel', mask: '(999) 999-9999' }
    case 'Email':
      return { type: 'email' }
    case 'Birthday':
      return { oldDate: true }
    case 'State':
      return { dropdownOptions: usStates }
    case 'Zip Code':
      return { mask: '99999' }
    default:
      return {}
  }
}

const TemplateInput = function (props) {
  const {
    inputSettings,
    themeColors,
    handleUpdate,
    errorMessage,
    primaryButtonColor,
    fieldSettings,
    onBlur,
  } = props
  const css = useStyles({
    themeColors,
    primaryButtonColor,
    fieldSettings,
    isError: !!errorMessage,
  })

  const renderText = () => {
    const templateProps = inputSettings.isTemplate
      ? getTemplateProps(inputSettings.label)
      : {}

    return (
      <Text
        placeholder={inputSettings.placeholder}
        handleUpdate={handleUpdate}
        onBlur={onBlur}
        errorMessage={errorMessage}
        inputProps={{
          classes: {
            root: css.rootInput,
            notchedOutline: css.outline,
            input: css.input,
          },
        }}
        {...templateProps}
      />
    )
  }

  const renderDropdown = () => {
    const templateProps = inputSettings.isTemplate
      ? getTemplateProps(inputSettings.label)
      : {}

    return (
      <Dropdown
        placeholder={inputSettings.placeholder}
        handleUpdate={handleUpdate}
        onBlur={onBlur}
        options={(
          templateProps.dropdownOptions ||
          inputSettings.dropdownOptions
        ).reduce((options, option) => {
          options.push([option, option])
          return options
        }, [])}
        inputProps={{
          classes: {
            root: css.rootInput,
            outlined: css.input,
            select: css.dropdownSelect,
          },
        }}
        placeholderClass={css.dropdownPlaceholder}
        {...templateProps}
      />
    )
  }

  const renderDate = () => {
    const templateProps = inputSettings.isTemplate
      ? getTemplateProps(inputSettings.label)
      : {}
    return (
      <DatePicker
        placeholder={inputSettings.placeholder}
        handleUpdate={handleUpdate}
        onBlur={onBlur}
        inputProps={{
          classes: {
            root: css.rootInput,
            notchedOutline: css.outline,
            input: css.input,
          },
        }}
        {...templateProps}
      />
    )
  }

  return (
    <div className={css.inputContainer}>
      {inputSettings.type === 'text' && renderText()}
      {inputSettings.type === 'dropdown' && renderDropdown()}
      {inputSettings.type === 'date' && renderDate()}
    </div>
  )
}

TemplateInput.propTypes = {
  inputSettings: PropTypes.object.isRequired,
  themeColors: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
  primaryButtonColor: PropTypes.string.isRequired,
  fieldSettings: PropTypes.object.isRequired,
}

TemplateInput.defaultProps = {
  errorMessage: null,
  onBlur: null,
}

export default TemplateInput

import axios from 'axios'

export default settings => async (action, additionalMeta) => {
  if (!settings) return
  const meta = {
    location: window.location.href,
    ...additionalMeta,
  }
  await axios.post(
    `${process.env.REACT_APP_DOJOMOJO_API}/metrics`,
    [{ action, meta }],
    {
      headers: { brand: settings.brandId },
      meta: { lightboxId: settings.id },
    },
  )
}

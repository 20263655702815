import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import PropTypes from 'prop-types'

import Page from 'components/Lightbox/Lightbox/Page'
import useSignupFormType from 'hooks/useSignupFormType'
import useSignupFormMode from 'hooks/useSignupFormMode'

const useStyles = makeStyles({
  pageContainer: {
    overflowY: 'hidden',
    width: 0,
  },
  pageContainerShow: {
    width: '100%',
  },
  page: {
    position: 'relative',
    boxSizing: 'border-box',
    padding: ({ noPadding }) => (noPadding ? '0' : '28px 24px'),
    background: ({ settings }) => settings.themeColors.background,
    display: 'table',
    height: '100%',
    minHeight: 387,
    width: ({ pageWidth }) => pageWidth,
  },
})

// render all of the pages so the tallest one sets the height of the signup form
const Pages = ({
  page,
  settings,
  formState,
  handleClose,
  handleContentUpdate,
  isErrorScreen,
}) => {
  const { isEmbed } = useSignupFormType(settings.settings)
  const { isDeliverMode } = useSignupFormMode()

  const isMobile = settings.currentViewMode === 'mobile'
  let pageWidth = isMobile ? 328 : 464
  if (isDeliverMode && isEmbed) pageWidth = '100%'

  const css = useStyles({
    settings,
    pageWidth,
    noPadding: isDeliverMode && isEmbed,
  })

  const pages = []
  // isShowingExtraPage - Used to show page that's not in the form.
  const isShowingExtraPage = isErrorScreen
  const pageCount = settings.pages.length
  const currentPage = isShowingExtraPage ? pageCount : page

  for (
    let pageIndex = 0;
    pageIndex < pageCount + (isShowingExtraPage ? 1 : 0);
    pageIndex += 1
  ) {
    const isCurrentPage = pageIndex === page

    pages.push(
      <div
        key={pageIndex}
        className={cn({
          [css.pageContainer]: true,
          [css.pageContainerShow]: pageIndex === currentPage,
        })}
      >
        <div className={css.page}>
          <Page
            settings={settings}
            formState={formState}
            pageIndex={pageIndex}
            isCurrentPage={isCurrentPage}
            isErrorScreen={isErrorScreen}
            handleClose={handleClose}
            handleContentUpdate={handleContentUpdate}
          />
        </div>
      </div>,
    )
  }

  return pages
}

Pages.propTypes = {
  page: PropTypes.number.isRequired,
  settings: PropTypes.object.isRequired,
  formState: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleContentUpdate: PropTypes.func.isRequired,
  isErrorScreen: PropTypes.bool.isRequired,
}

export default Pages

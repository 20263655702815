import React from 'react'
import axios from 'axios'

import Lightbox from 'components/Lightbox'

const flatten = arr => {
  let flattenedArray = []
  for (let i = 0; i < arr.length; i += 1) {
    if (Array.isArray(arr[i])) {
      flattenedArray = flattenedArray.concat(flatten(arr[i]))
    } else {
      flattenedArray.push(arr[i])
    }
  }
  return flattenedArray
}

const getBrandIdFromScript = () => {
  const scriptTags = document.getElementsByTagName('script')
  let brandId = null
  for (let i = 0; i < scriptTags.length; i += 1) {
    const scriptTag = scriptTags[i]
    brandId =
      scriptTag.dataset.dojomojoId || scriptTag.getAttribute('dojomojo-id')
    if (brandId) break
  }
  return brandId
}

export const fetchBrandFormConditions = async () => {
  const brandId = getBrandIdFromScript()

  if (!brandId) {
    console.error('DojoMojo Signup Form integration error: brand ID.')
    return null
  }

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_DOJOMOJO_API}/lbx/brands/${brandId}/lightboxes/conditions`,
    )
    return data
  } catch (e) {
    console.error('DojoMojo Signup Form integration error: brand settings.')
    return null
  }
}

const fetchSignupFormSettings = async signupFormIds => {
  const rejoinedIds = signupFormIds.join(',')
  const endpoint = `/lbx/lightboxes/deliver?ids=${rejoinedIds}`
  const res = await axios.get(
    `${process.env.REACT_APP_DOJOMOJO_API}${endpoint}`,
  )
  return res.data
}

// Note: Iframes only rendered on initial load when no mode available
// (i.e. isEditMode, isPreviewMode, and isDeliverMode all false)
const buildIframe = (formId, formType, appendParent) => {
  const iframeElement = document.createElement('iframe')
  iframeElement.id = `dojo-iframe-${formId}`

  const signupFormUrl = process.env.REACT_APP_ENV_SIGNUP_FORM_WEB
  iframeElement.src = `${signupFormUrl}/index.html?deliver=${formId}`

  // Styles that are same across popups and embeds
  iframeElement.style.border = 'none'

  if (formType === 'embed') {
    iframeElement.style.position = 'relative'
    iframeElement.width = '100%'
    iframeElement.height = '100%'
  }
  if (formType === 'popup') {
    const popupStyle = {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      'z-index': '100000',
    }
    Object.assign(iframeElement.style, popupStyle)
  }

  appendParent.appendChild(iframeElement)

  return iframeElement
}

const buildEmbedIframes = (settings, embedDivs) => {
  const formComponents = []
  // Iterate through all matching embedDivs, then build iframes for each
  // Note: Cannot use array methods because embedDivs is a NodeList
  for (let i = 0; i < embedDivs.length; i += 1) {
    const embedDiv = embedDivs.item(i) // Can access NodeList element with .item()
    embedDiv.style.position = 'relative'
    embedDiv.style.display = 'block'
    embedDiv.style.width = '100%'
    buildIframe(settings.id, settings.type, embedDiv)
    const formComponent = <Lightbox key={settings.id} settings={settings} />
    formComponents.push(formComponent)
  }

  return formComponents
}

export const generateSignupForms = async (signupFormIds, modes) => {
  const { isInitializing } = modes
  const settingsMapBySignupFormId = await fetchSignupFormSettings(signupFormIds)

  // Map through all signupFormIds to determine what to render
  const allSignupForms = signupFormIds
    .map(signupFormId => {
      const settings = settingsMapBySignupFormId[signupFormId]

      if (!isInitializing) {
        return <Lightbox key={settings.id} settings={settings} />
      }

      if (settings.type === 'embed') {
        // Brands must place anchor divs with same className as embedDivClassName;
        // checks for DOM elements that match the signupFormId and build embed Iframes
        const embedDivClassName = `.dojo-embed-root-${signupFormId}`
        const embedDivs = document.querySelectorAll(embedDivClassName)
        if (embedDivs.length > 0) {
          const lightboxComponents = buildEmbedIframes(settings, embedDivs)
          return lightboxComponents
        }
      } else {
        const formType = settings.type || 'popup'
        buildIframe(settings.id, formType, window.document.body)
        return <Lightbox key={settings.id} settings={settings} />
      }
      return null
    })
    .filter(Boolean)

  // allSignupForms is an array of arrays, where each array element is an array of
  // Iframes for every embed container that has a matching signupFormId
  return flatten(allSignupForms)
}

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import useFontLoader from 'hooks/useFontLoader'

const useStyles = makeStyles({
  previewTestWarningContainer: {
    visibility: 'visible',
    position: ({ isEmbed }) => (isEmbed ? 'relative' : 'absolute'),
    top: ({ isEmbed }) => (isEmbed ? 0 : 20),
    backgroundColor: '#F4F7F8',
    maxWidth: 432,
    borderRadius: 4,
    padding: 16,
    display: 'flex',
    gap: '12px',
    zIndex: 1,
    boxShadow: '0px 2px 4px rgb(0,0,0,0.2)',
  },
  warningText: {
    margin: 0,
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#172931',
    letterSpacing: '0.15px',
    fontFamily: "'Poppins'",
  },
  infoIcon: {
    transform: 'scale(2)',
    height: 20,
    margin: '4px 4px 0 4px',
  },
})

function PreviewWarningBanner({ formType }) {
  useFontLoader({ isLoaded: true, settings: null, isEditMode: true }, [
    'Poppins',
  ])

  const isEmbed = formType === 'embed'
  const css = useStyles({ isEmbed })

  return (
    <div className={css.previewTestWarningContainer}>
      {/* info icon */}
      <svg
        className={css.infoIcon}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
          fill="#3F5059"
        />
      </svg>
      <p className={css.warningText}>
        Heads up, this is a test preview! When you submit your phone number,
        you’ll get a test text message.
      </p>
    </div>
  )
}

PreviewWarningBanner.propTypes = {
  formType: PropTypes.string.isRequired,
}

export default React.memo(PreviewWarningBanner)

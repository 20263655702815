import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import SettingsContext from 'contexts/Settings'
import useLightboxSettings from '@innovationdepartment/dojo-signups-web-settings'
import axios from 'axios'
import useFontLoader from 'hooks/useFontLoader'
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline'
import metricsUtil from 'utils/metrics'
import useSignupFormMode from 'hooks/useSignupFormMode'
import useSignupFormType from 'hooks/useSignupFormType'

import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles'
import applySameStylesToFieldInAllPages from 'utils/applySameStylesToFieldInAllPages'
import useScriptsLoader from 'hooks/useScriptsLoader'
import Lightbox from './Lightbox'

const defaultFonts = ['Montserrat', 'Source Sans Pro']

const generateClassName = createGenerateClassName({ seed: 'dojo' })

const sendToIframeParent = data => {
  window.top.postMessage(JSON.stringify(data), '*')
}

function LightboxContainer({ settings: formSettings }) {
  const { isLoaded, setSettings, getSettings } = useLightboxSettings()

  const autoCloseTimeoutRef = useRef(null)
  const formState = useRef([]) // Form values
  const colorPicker = useRef(null)
  const lightboxElement = useRef(null)

  const [page, setPage] = useState(0)
  const [isShowing, setIsShowing] = useState(true)
  const [alreadySubmitted, setAlreadySubmitted] = useState(false)
  const [isErrorScreen, setIsErrorScreen] = useState(false)
  const [formStateReady, setFormStateReady] = useState(false)
  // show title hover state in edit mode to let brands know that they can edit the title
  const [hasHoveredEditable, setHasHoveredEditable] = useState(false)

  const {
    isEditMode,
    isPreviewMode,
    isDeliverMode,
    isInitializing,
    isFromDojoMojo,
  } = useSignupFormMode()

  useEffect(() => {
    if (formSettings) setSettings(formSettings)
  }, [formSettings])

  const settings = getSettings()

  const windowInnerWidth = window.innerWidth
  useEffect(() => {
    if (settings && windowInnerWidth < 700) settings.viewMode = 'mobile'
  }, [settings, windowInnerWidth])

  const { isPopup, isEmbed } = useSignupFormType(
    formSettings || settings?.settings,
  )

  let metrics = null

  useFontLoader({ isLoaded, settings, isEditMode }, defaultFonts)
  const { loadEditModeScripts, isJsScriptsLoaded } = useScriptsLoader()

  const setIframeHeight = height => {
    if (!formSettings) return
    const iframeId = `#dojo-iframe-${formSettings.id}`
    const iframeElements =
      window.parent.document.querySelectorAll(iframeId) || []
    if (iframeElements.length > 0) {
      iframeElements.forEach(iframeElement => {
        // eslint-disable-next-line
        iframeElement.height = `${height}px`
      })
    }
  }

  useEffect(() => {
    if (
      !lightboxElement.current ||
      !isEmbed ||
      !formStateReady ||
      isPreviewMode ||
      isEditMode
    ) {
      return
    }
    const formHeight = lightboxElement.current.offsetHeight || 0
    if (formHeight > 0) {
      sendToIframeParent({
        source: `dojo-iframe-${formSettings.id}`,
        height: formHeight,
      })
    }
  }, [isInitializing, isEmbed, formStateReady, lightboxElement.current])

  const processMessage = rawMessage => {
    let message
    try {
      message = JSON.parse(rawMessage.data)
    } catch {
      return
    }
    if (
      formSettings &&
      message.source === `dojo-iframe-${formSettings.id}` &&
      message.close
    ) {
      // eslint-disable-next-line
      handleClose(`dojo-iframe-${formSettings.id}`)
    }
    if (
      formSettings &&
      message.source === `dojo-iframe-${formSettings.id}` &&
      message.height
    ) {
      setIframeHeight(message.height)
    }
    if (message.source !== 'builder') return
    if (message.settings) {
      setSettings(message.settings)
    }
    if (message.viewMode) {
      getSettings().viewMode = message.viewMode
    }
    if (typeof message.page !== 'undefined') {
      setPage(message.page)
    }
  }

  const initBuilderMessages = () => {
    window.addEventListener(`message`, processMessage)
  }

  const clearBuilderMessages = () => {
    window.removeEventListener(`message`, processMessage)
  }

  const calculateExpirationDate = delayInDays => {
    const date = new Date()
    const delayInHours = delayInDays * 24
    date.setHours(date.getHours() + delayInHours)
    return date
  }

  const setDisplaySettingsOnLocalStorage = () => {
    if (!formSettings) return

    const storageKey = `dojomojo_signup_form_${formSettings.id}`
    const { displayFrequency, displayFrequencyDelay } = formSettings.trigger

    try {
      // Clear existing storageKey since brand can change display settings
      const existingStorageKey = JSON.parse(localStorage.getItem(storageKey))
      if (existingStorageKey) localStorage.removeItem(storageKey)

      // Don't include an expiration (displayFrequency === 'never' is the default)
      const storageData = { hide: true }

      if (displayFrequency === 'every-session') {
        // Delay showing again for a single day
        const delayInDays = 1
        storageData.expiration = calculateExpirationDate(delayInDays)
      }

      if (displayFrequency === 'delay') {
        const delayInDays = displayFrequencyDelay
        storageData.expiration = calculateExpirationDate(delayInDays)
      }

      localStorage.setItem(storageKey, JSON.stringify(storageData))
    } catch (e) {
      console.error('Unable to access localStorage', e)
    }
  }

  const handleClose = uniqueIframeId => {
    if (isEditMode || isPreviewMode) return
    setIsShowing(false)
    if (isPopup && uniqueIframeId) {
      const iframeElement = document.getElementById(uniqueIframeId)
      if (!iframeElement) return
      window.document.body.removeChild(iframeElement)
      setDisplaySettingsOnLocalStorage()
    }
  }

  const handleErrorScreen = (logMessage = null, errorObj = null) => {
    setIsErrorScreen(true)
    metrics('lightboxError', {
      lightboxId: settings.id,
      message: logMessage,
      errorObj,
    })
  }

  const getFormStateValues = () => {
    const formValues = {}
    formState.current.forEach(formPage => {
      formPage.forEach(formInput => {
        formValues[formInput.label] = formInput.value
      })
    })
    return formValues
  }

  const handleSendCloseToIframeParent = () => {
    if (!formSettings) return
    sendToIframeParent({
      source: `dojo-iframe-${formSettings.id}`,
      close: true,
    })
  }

  const handleAutomaticClosing = () => {
    if (isEditMode || isPreviewMode || settings.autoCloseDelay === null) return
    const delayInSeconds = settings.autoCloseDelay * 1000
    autoCloseTimeoutRef.current = setTimeout(() => {
      handleSendCloseToIframeParent()
    }, delayInSeconds)
  }

  // Automatically closes popups when user reaches Thank You page
  useEffect(() => {
    if (!settings) return null
    const isThankYouPage = page === settings.pages.length - 1
    if (isThankYouPage && isPopup) handleAutomaticClosing()
    return () => clearTimeout(autoCloseTimeoutRef.current)
  }, [page, settings, isPopup])

  const submitDataToBackend = async () => {
    try {
      // setting alreadySubmitted to true in order to prevent
      // partial submission when clicking on close
      setAlreadySubmitted(true)
      const { status } = await axios.post(
        `${process.env.REACT_APP_DOJOMOJO_API}/sms-lightbox-entry`,
        {
          values: getFormStateValues(),
          brandId: formSettings.brandId,
          id: formSettings.id,
        },
      )
      if (status === 200) {
        // setFormSubmitting(false)
        // setSubmitSuccess(true)
        // document.cookie = 'dojomojo_lightbox_entry=true;max-age=315360000;'
        // registerEvent('lightboxSubmitSuccess', { values })
      }
    } catch (err) {
      handleErrorScreen(err.message, err)
      // if error, kill auto close timeout
      clearTimeout(autoCloseTimeoutRef.current)
      // setFormSubmitting(false)
      // setSubmitFailed(true)
      // registerEvent('lightboxSubmitFail', { values })
      // console.error('Entry submit failed', err)
    }
  }

  const partiallySubmitDataThenCloseParentIframe = () => {
    // if data was already submitted (via last page before thank you page)
    // then clicked in regular close button, dont do partial
    // submission and just close straight up parent iframe
    if (alreadySubmitted) {
      handleSendCloseToIframeParent()
      return
    }
    const values = getFormStateValues()

    // if important form values exists to interact with integrations, then do partial submission
    // with whatever data has been gathered from the form
    if (values.Email || values.Phone) {
      submitDataToBackend().then(handleSendCloseToIframeParent)
    } else {
      // default behavior
      handleSendCloseToIframeParent()
    }
  }

  // initializes the state
  useEffect(() => {
    if (!isLoaded) return
    // init metrics
    metrics = metricsUtil(settings)

    // set empty form
    formState.current = settings.pages.map(eachPage =>
      eachPage.inputs.map(input => ({
        label: input.label,
        isRequired: input.isRequired,
        value: '',
        isTemplate: input.isTemplate,
      })),
    )

    setFormStateReady(true)

    if (isDeliverMode) {
      metrics('lightboxDisplayed', { lightboxId: formSettings.id })
    }
  }, [isLoaded])

  useEffect(() => {
    // Do not open for popup users coming from dojo
    if (isFromDojoMojo) handleClose(`dojo-iframe-${formSettings.id}`)

    if (isEditMode) {
      initBuilderMessages()
      loadEditModeScripts()
      return clearBuilderMessages
    }

    // if live mode
    initBuilderMessages() // listen for close event
    return clearBuilderMessages
  }, [isLoaded])

  useEffect(() => {
    if (isJsScriptsLoaded && isEditMode) {
      //eslint-disable-next-line
      colorPicker.current = Pickr.create({
        el: '.pickr',
        theme: 'monolith',
        components: {
          preview: true,
          opacity: true,
          hue: true,
          interaction: {
            hex: true,
            rgba: true,
            input: true,
            save: true,
          },
        },
      })
    }
  }, [isEditMode, isJsScriptsLoaded])

  const sendSettingsToParent = () => {
    sendToIframeParent({
      settings: settings.getSaveObj(),
      source: 'builder',
    })
  }

  const handleContentUpdate = (field, contentPage) => value => {
    settings.pages[contentPage][field] = { content: value }
    applySameStylesToFieldInAllPages(settings, {
      page: contentPage,
      field,
    })
    sendSettingsToParent()
  }

  const notReady = !isLoaded || !isShowing || (isEditMode && !isJsScriptsLoaded)
  const shouldNotRender = isInitializing || !settings
  if (notReady || shouldNotRender || !formStateReady) return null

  return (
    <SettingsContext.Provider
      value={{
        settings,
        // number of the page
        page,
        setPage,
        isEditMode,
        isPreviewMode,
        // can be used to hide the lightbox
        setIsShowing,
        // sends settings to the builder outside of the iframe
        sendSettingsToParent,
        // has hovered over a wysiwyg setting
        hasHoveredEditable,
        // set if the user has hovered over the wysiwyg
        setHasHoveredEditable,
        metrics,
        // reference to the color picker for all of the WYSIWYG to share
        colorPicker,
        // show the lightbox error screen and record an error.
        // Only use this as a last resort for unrecoverable errors.
        handleErrorScreen,
        submitDataToBackend,
      }}
    >
      <StylesProvider generateClassName={generateClassName}>
        <Lightbox
          page={page}
          settings={settings}
          setPage={setPage}
          isEditMode={isEditMode}
          isPreviewMode={isPreviewMode}
          handleClose={partiallySubmitDataThenCloseParentIframe}
          getRef={lightboxElement}
          handleContentUpdate={handleContentUpdate}
          formState={formState.current}
          isErrorScreen={isErrorScreen}
        />
        <ScopedCssBaseline />
      </StylesProvider>
    </SettingsContext.Provider>
  )
}

LightboxContainer.propTypes = {
  settings: PropTypes.object,
}

LightboxContainer.defaultProps = {
  settings: null,
}

export default LightboxContainer

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import cn from 'classnames'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    '&:hover .dojo-editable-tab': {
      display: isEditMode => (isEditMode ? 'flex' : 'none'),
    },
  },
  containerHover: {
    '& .dojo-editable-tab': {
      display: isEditMode => (isEditMode ? 'flex' : 'none'),
    },
  },
  viewContainerInner: {
    border: isEditMode =>
      isEditMode ? '2px solid rgba(0,0,0,0)' : 'none',
    '&:hover': {
      border: isEditMode =>
        isEditMode ? '2px solid #86A0AC' : 'none',
    },
    '& p': {
      margin: 0,
    },
  },
  viewContainerHover: {
    border: isEditMode => (isEditMode ? '2px solid #86A0AC' : 'none'),
  },
  editContainer: {
    border: '2px solid #008DE6',
    position: 'relative',
  },
  hide: {
    display: 'none',
  },
  tab: {
    color: '#fff',
    height: 18,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: '-26px',
    pointerEvents: 'none',
    padding: '4px 12px',
    borderRadius: '2px 2px 0px 0px',
  },
  clickToEditTab: {
    background: '#86A0AC',
    display: 'none',
  },
  editingTab: {
    background: '#008DE6',
    display: 'flex',
  },
  renderEditIcon: {
    marginLeft: 5,
  },
})

function Editable(props) {
  const {
    value,
    getTextRef,
    handleClick,
    isEditing,
    isEditMode,
    isHoverActive,
    handleHover,
  } = props
  const css = useStyles(isEditMode)

  const renderEditIcon = () => (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={css.renderEditIcon}
    >
      <path
        d="M0.126953 7.62478V9.49979H2.00195L7.53195 3.96978L5.65695 2.09479L0.126953 7.62478ZM8.98195 2.51979C9.17695 2.32479 9.17695 2.00979 8.98195 1.81479L7.81195 0.644785C7.61695 0.449785 7.30195 0.449785 7.10695 0.644785L6.19195 1.55979L8.06695 3.43479L8.98195 2.51979Z"
        fill="white"
      />
    </svg>
  )

  return (
    <div
      className={cn({
        [css.container]: true,
        [css.containerHover]: isHoverActive,
      })}
      onClick={handleClick}
      onMouseEnter={handleHover}
    >
      <div
        className={cn({
          [css.tab]: true,
          [css.clickToEditTab]: !isEditing,
          [css.editingTab]: isEditing,
          'dojo-editable-tab': true,
        })}
      >
        {isEditing ? 'Editing' : 'Click to edit'}
        {renderEditIcon()}
      </div>
      <div
        className={cn({
          [css.viewContainerInner]: true,
          [css.viewContainerHover]: isHoverActive,
          [css.hide]: isEditing,
          'ql-editor': true,
        })}
        dangerouslySetInnerHTML={{ __html: value }}
      />
      <div
        className={cn({
          [css.editContainer]: true,
          [css.hide]: !isEditing,
        })}
      >
        <div ref={getTextRef} />
      </div>
    </div>
  )
}

Editable.propTypes = {
  value: PropTypes.string.isRequired,
  getTextRef: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  handleHover: PropTypes.func.isRequired,
  isHoverActive: PropTypes.bool.isRequired,
}

export default Editable

import React, { useContext, useState } from 'react'
import SettingsContext from 'contexts/Settings'
import PropTypes from 'prop-types'
import applySameStylesToFieldInAllPages from 'utils/applySameStylesToFieldInAllPages'
import isAllowedAreaCode from 'utils/isAllowedAreaCode'
import Form from './Form'

function FormContainer(props) {
  const {
    settings,
    setPage,
    sendSettingsToParent,
    isEditMode,
    submitDataToBackend,
  } = useContext(SettingsContext)
  const [formErrors, setFormErrors] = useState({})
  const { handleClose, page, formState, isCurrentPage } = props
  
  const validateForm = () => {
    const newErrors = formState[page].reduce((errors, input) => {
      let error = null
      if (input.isRequired && input.value === '') {
        error = 'This field is required.'
      } else if (
        input.label === 'Phone' &&
        String(input.value).length !== 14 &&
        input.value !== ''
      ) {
        error = 'Invalid phone number'
      } else if (
        input.label === 'Phone' &&
        input.value !== '' &&
        !isAllowedAreaCode(input.value)
      ) {
        error =
          'Sorry, our SMS program is for US phone numbers only. Thank you for your interest.'
      } else if (
        input.label === 'Zip Code' &&
        String(input.value).length !== 5 &&
        input.value !== ''
      ) {
        error = 'Invalid zip code'
      } else if (
        input.label === 'Email' &&
        input.value !== '' &&
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          input.value,
        )
      ) {
        error = 'Invalid email address'
      }
      return {
        ...errors,
        ...(error !== null ? { [input.label]: error } : {}),
      }
    }, {})

    setFormErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleFormUpdate = fieldLabel => newValue => {
    const field = formState[page].find(input => input.label === fieldLabel)
    if (!field) return

    field.value = newValue

    if (formErrors[fieldLabel]) {
      setFormErrors(prevFormErrors => {
        const newFormErrors = { ...prevFormErrors }
        delete newFormErrors[fieldLabel]
        return newFormErrors
      })
    }
  }

  const handleContentUpdate = (field, contentPage) => value => {
    settings.pages[contentPage][field] = { content: value }
    applySameStylesToFieldInAllPages(settings, { page: contentPage, field })
    sendSettingsToParent()
  }

  const handleSubmit = async () => {
    const isThankYouPage = page === settings.pages.length - 1

    if (isThankYouPage) {
      handleClose()
      return
    }

    if (!validateForm()) return

    setPage(prevPage => prevPage + 1)

    await submitDataToBackend()
  }

  return (
    <Form
      isCurrentPage={isCurrentPage}
      handleBlur={validateForm}
      settings={settings}
      page={page}
      handleFormUpdate={handleFormUpdate}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      formErrors={formErrors}
      handleContentUpdate={handleContentUpdate}
      isEditMode={isEditMode}
    />
  )
}

FormContainer.propTypes = {
  isCurrentPage: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  formState: PropTypes.array.isRequired,
}

export default FormContainer

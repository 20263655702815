import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'

const DatePicker = function (props) {
  const { placeholder, inputProps, oldDate, handleUpdate, onBlur } = props

  const [date, setDate] = useState(null)

  const handleChange = newDate => {
    if (newDate) {
      handleUpdate(
        [
          newDate.year,
          String(newDate.month).padStart(2, '0'),
          String(newDate.day).padStart(2, '0'),
        ].join('-'),
      )
      setDate(newDate)
    }
  }

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <KeyboardDatePicker
        onBlur={onBlur}
        disableToolbar
        inputVariant="outlined"
        format="MM/dd/yyyy"
        placeholder={placeholder}
        value={date}
        onChange={handleChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        openTo={oldDate ? 'year' : 'date'}
        fullWidth={true}
        InputProps={inputProps}
        disableFuture={oldDate}
      />
    </MuiPickersUtilsProvider>
  )
}

DatePicker.propTypes = {
  placeholder: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  oldDate: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
}

DatePicker.defaultProps = {
  inputProps: {},
  oldDate: false,
  onBlur: null,
}

export default DatePicker

import React from 'react'
import ReactDOM from 'react-dom'

import reportWebVitals from './reportWebVitals'
import App from './App'

window.addEventListener('load', () => {
  // If the script is loaded via script tag on the brand's
  // website then add the required div container
  const divId = 'dojo-signup-form-root'
  let containerDiv = document.getElementById(divId)
  if (!containerDiv) {
    containerDiv = document.createElement('div')
    containerDiv.id = divId
    document.body.appendChild(containerDiv)
  }
  
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    containerDiv,
  )
})

reportWebVitals()

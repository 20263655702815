import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from 'ui/Button'

const useStyles = makeStyles({
  container: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 8,
  },
  subtitle: {
    color: '#F14D4D',
    fontFamily: 'Arial',
    fontSize: 14,
    lineHeight: '22px',
    textAlign: 'center',
    marginBottom: 32,
  },
  button: {
    height: 48,
    '&:hover': {
      opacity: '65%',
    },
  },
})

const ErrorScreen = () => {
  const css = useStyles()

  const handleTryAgain = () => {
    // eslint-disable-next-line
    location.reload()
  }

  return (
    <div className={css.container}>
      <div className={css.title}>
        Oh no! <br /> Something went wrong
      </div>
      <div className={css.subtitle}>
        We were not able to process this.
      </div>
      <Button
        onClick={handleTryAgain}
        inputProps={{
          classes: {
            root: css.button,
            label: css.buttonLabel,
          },
        }}
      >
        Try Again
      </Button>
    </div>
  )
}

export default ErrorScreen

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  closeIcon: {
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 1,
    fill: ({ iconFill }) => iconFill,
    cursor: 'pointer',
  },
})

const CloseIcon = ({ handleClose, iconFill }) => {
  const css = useStyles({ iconFill })

  return (
    <svg
      className={css.closeIcon}
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClose}
    >
      <path d="M14 1.67147L12.59 0.261475L7 5.85147L1.41 0.261475L0 1.67147L5.59 7.26147L0 12.8515L1.41 14.2615L7 8.67147L12.59 14.2615L14 12.8515L8.41 7.26147L14 1.67147Z" />
    </svg>
  )
}

CloseIcon.propTypes = {
  handleClose: PropTypes.func.isRequired,
  iconFill: PropTypes.string.isRequired,
}

export default CloseIcon

export default function removeNewLinesFromContent(contentBlocks) {
  let keepBlock = true
  return contentBlocks
    .filter((content, index) => {
      // avoid first block to be \n
      if (!index && /^\n+$/.test(content.insert)) return false
      // avoid subsequent blocks to be empty
      if (content.insert === '') keepBlock = false
      return keepBlock
    })
    // if content block = \n\n\n\n just keep it as \n
    // one block with \n is needed to keep extra styles, ex. align-center
    .map(content => {
      if (!/^\n+$/.test(content.insert)) {
        // remove new lines after/before from (pasted?) text
        // eslint-disable-next-line no-param-reassign
        content.insert = content.insert.trim()
        return content
      }
      // also, last block needs to be { insert: '\n' /* extra styling as "attributes: {}""  */ }
      return {
        ...content,
        insert: /^(\n)+$/.exec(content.insert)[1]
      }
    })
}

import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'

const Text = function (props) {
  const {
    fullWidth,
    placeholder,
    inputProps,
    type,
    mask,
    handleUpdate,
    onBlur,
    errorMessage,
  } = props

  const [value, setValue] = useState('')

  const handleChange = event => {
    handleUpdate(event.target.value)
    setValue(event.target.value)
  }

  return (
    <InputMask
      // mask must not be present when empty for auto-fill to work
      mask={value ? mask : null}
      value={value}
      onChange={handleChange}
      disabled={false}
      maskPlaceholder={null}
      onBlur={onBlur}
    >
      <TextField
        fullWidth={fullWidth}
        variant="outlined"
        placeholder={placeholder}
        InputProps={inputProps}
        type={type}
        error={!!errorMessage}
        helperText={errorMessage}
      />
    </InputMask>
  )
}

Text.propTypes = {
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  type: PropTypes.string,
  mask: PropTypes.string,
  errorMessage: PropTypes.string,
  handleUpdate: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
}

Text.defaultProps = {
  fullWidth: true,
  placeholder: null,
  onBlur: null,
  inputProps: {},
  type: 'text',
  mask: null,
  errorMessage: null,
}

export default Text

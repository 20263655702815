import { useState } from 'react'

import loadJsScript from 'utils/loadJsScript'

//TODO: move script loader and all logic somewhere else
const initLoad = {
  scripts: ['/js/quill.js', '/js/pickr.min.js'],
  styles: [
    '/styles/quill.snow.css',
    '/styles/quill.custom.css',
    '/styles/pickr.monolith.min.css',
  ],
}

export default function useScriptsLoader() {
  const [isJsScriptsLoaded, setIsJsScriptsLoaded] = useState(false)
  const loadEditModeScripts = () => {
    setIsJsScriptsLoaded(false)
    Promise.all(initLoad.scripts.map(loadJsScript)).then(() => {
      setIsJsScriptsLoaded(true)
    })
    initLoad.styles.forEach(style => {
      const styleElement = document.createElement('link')
      styleElement.setAttribute('href', style)
      styleElement.setAttribute('rel', 'stylesheet')
      document.head.appendChild(styleElement)
    })
  }

  return { isJsScriptsLoaded, loadEditModeScripts }
}

import wysiwygConfig from 'components/Editable/wysiwygConfig'

const skipField = {}

export default function applySameStylesToFieldInAllPages(settings, opts) {
  const { field, page: currentPage } = opts

  if (skipField[field]) return

  // quill needs to be in a child with its parent element (not sure why this?)
  const div = document.createElement('div')
  const child = document.createElement('div')
  div.appendChild(child)

  // eslint-disable-next-line
  const quilljs = new Quill(child, wysiwygConfig)

  // https://quilljs.com/docs/delta/#delta
  const pageDeltas = settings.pages
    .map(page => {
      const { content } = page[field]

      return quilljs.clipboard.convert(content).ops
    })

  const copyFromDelta = pageDeltas[currentPage]

  const skipCopyingStyles = !pageDeltas.every(delta => delta.length === copyFromDelta.length)

  if (skipCopyingStyles) skipField[field] = true
  else {
    pageDeltas.forEach((copyToDelta, index) => {
      if (index === currentPage) return
      const copyTo = copyToDelta
      const copyFrom = copyFromDelta

      /* Here is where the magic happens! */
      const newDelta = [].concat(
        copyTo.map((block, idx) => {
          const newBlock = { ...block }
          if (copyFrom[idx].attributes) newBlock.attributes = copyFrom[idx].attributes
          return newBlock
        }),
        // last block needs to be { insert: '\n' } (text align just cuz it breaks the default styles without it)
        { insert: '\n', attributes: { align: 'center'} }
      )

      quilljs.setContents(newDelta, 'api')

      // eslint-disable-next-line
      settings.pages[index][field] = { content: quilljs.root.innerHTML }
    })
  }
}

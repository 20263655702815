import fonts from './fonts.json'

const wysiwygConfig = {
  // debug: 'info',
  modules: {
    toolbar: [
      {
        font: fonts.map(font => font.replace(/[+,-]/g, '')),
      },
      {
        size: [
          '5px',
          '6px',
          '7px',
          '8px',
          '9px',
          '10px',
          '11px',
          '12px',
          '13px',
          '14px',
          '15px',
          '16px',
          '17px',
          '18px',
          '19px',
          '20px',
          '22px',
          '24px',
          '26px',
          '28px',
          '30px',
          '32px',
          '34px',
          '36px',
          '38px',
          '40px',
          '44px',
          '48px',
          '50px',
          '55px',
          '60px',
          '72px',
          '80px',
          '96px',
        ],
      },
      { color: null },
      'divider',
      'bold',
      'italic',
      'divider',
      { align: '' },
      { align: 'center' },
      { align: 'right' },
    ],
  },
  placeholder: 'Compose an epic...',
  theme: 'snow',
}

export default wysiwygConfig
import React, { useState, useEffect, useRef } from 'react'

import Lightbox from 'components/Lightbox'

import useSignupFormMode from 'hooks/useSignupFormMode'
import useLightboxTrigger from 'hooks/useLightboxTrigger'
import {
  generateSignupForms,
  fetchBrandFormConditions,
} from 'utils/initialize-forms'

function App() {
  const triggeredFormIdsSet = useRef(null)
  const renderedFormIdsSet = useRef(null)
  const renderedForms = useRef(null)

  const [loading, setLoading] = useState(true)
  const [signupFormIds, setSignupFormIds] = useState([])
  const [conditionSettings, setConditionSettings] = useState(null)
  const [, setUpdate] = useState({})
  const rerender = () => {
    setUpdate({})
  }
  const modes = useSignupFormMode()
  const { params, isEditMode, isPreviewMode, isDeliverMode } = modes

  const conditions = conditionSettings?.conditions || null
  const { triggeredLightboxIds } = useLightboxTrigger(conditions) // IDs from conditions

  useEffect(() => {
    // Tracks unique formIds that are triggered/rendered to prevent duplicates
    triggeredFormIdsSet.current = new Set()
    renderedFormIdsSet.current = new Set()
    // Stores record of rendered form components
    renderedForms.current = new Set()
  }, [])

  const parseSignupFormIds = param => {
    const ids = params.get(param)
    return ids?.split(',') || []
  }

  useEffect(() => {
    // Fetches brand conditions using brandId pulled from the Dojo script
    // before any parameters are available (i.e. "preview" and "deliver")
    const getAndSetBrandConditions = async () => {
      const brandConditions = await fetchBrandFormConditions()
      setConditionSettings(brandConditions)
      setLoading(false)
    }

    // Edit mode grabs form ID and settings from inside the builder
    if (isEditMode) return

    // Preview and deliver modes grab signupFormIds from query params
    let lookupKey = ''
    if (isPreviewMode) lookupKey = 'preview'
    if (isDeliverMode) lookupKey = 'deliver'

    if (lookupKey) {
      const ids = parseSignupFormIds(lookupKey)
      setSignupFormIds(ids)
      setLoading(false)
      return
    }

    // If no view mode is found, it implies the initial app load (i.e. iframe)
    // and form conditions are fetched using brandId from the Dojo script
    getAndSetBrandConditions()
  }, [isEditMode, isPreviewMode, isDeliverMode])

  useEffect(() => {
    if (triggeredLightboxIds.length > 0) {
      const newTriggeredFormIds = triggeredLightboxIds.filter(
        id => !triggeredFormIdsSet.current.has(id),
      )
      newTriggeredFormIds.forEach(
        triggeredFormIdsSet.current.add,
        triggeredFormIdsSet.current,
      )
      setSignupFormIds(newTriggeredFormIds)
    }
  }, [triggeredLightboxIds])

  useEffect(() => {
    if (loading || !signupFormIds.length) return

    const initSignupForms = async ids => {
      const signupFormComponents = await generateSignupForms(ids, modes)
      signupFormComponents.forEach(
        renderedForms.current.add,
        renderedForms.current,
      )
      rerender()
    }

    const newRenderedFormIds = signupFormIds.filter(
      id => !renderedFormIdsSet.current.has(id),
    )
    newRenderedFormIds.forEach(
      renderedFormIdsSet.current.add,
      renderedFormIdsSet.current,
    )
    initSignupForms(newRenderedFormIds)
  }, [loading, isEditMode, isDeliverMode, signupFormIds])

  if (isEditMode) return <Lightbox settings={null} />

  return renderedForms.current
}

export default App

import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import ClickAwayListener from 'components/ClickAwayListener'
import SettingsContext from 'contexts/Settings'
import removeNewLinesFromContent from 'utils/removeNewLinesFromContent'

import wysiwygConfig from 'components/Editable/wysiwygConfig'
import Editable from './Editable'

function EditableContainer(props) {
  const { value, handleChange, isHoverActiveOnLoad } = props
  const textElement = useRef(null)
  const quill = useRef(null)
  const timeoutRef = useRef(null)
  const [isEditing, setIsEditing] = useState(false)
  const isShowingColorPicker = useRef(false)

  const {
    isEditMode,
    hasHoveredEditable,
    setHasHoveredEditable,
    colorPicker,
  } = useContext(SettingsContext)

  const handleTextColorUpdate = useCallback(color => {
    quill.current.format('color', color.toRGBA().toString())
    colorPicker.current.hide()
  }, [])

  const handleColorPickerClose = useCallback(() => {
    // Delay is here so clicking outside of the colorpicker
    // wouldn't count as clicking outside of the wysiwyg
    // causing both the picker and wysiwyg to exit.
    // I welcome a better solution
    setTimeout(() => {
      isShowingColorPicker.current = false
    }, 100)
  })

  const handleShowColorPicker = () => {
    colorPicker.current.show()
    isShowingColorPicker.current = true
  }

  const handleClick = () => {
    if (isEditMode && !isEditing) {
      quill.current.clipboard.dangerouslyPasteHTML(value)
      textElement.current.click()
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        quill.current.setSelection(Infinity) // move cursor to the end
      }, 0)
      setIsEditing(true)
      colorPicker.current.on('save', handleTextColorUpdate)
      colorPicker.current.on('hide', handleColorPickerClose)
    }
  }

  const handleCloseAndSave = () => {
    // don't close the WYSIWYG when clicking on the color pickr
    if (isShowingColorPicker.current) return
    // clean up
    colorPicker.current.off('save', handleTextColorUpdate)
    colorPicker.current.off('hide', handleColorPickerClose)
    setIsEditing(false)

    // removes extra lines before submitting html
    quill.current.setContents(
      removeNewLinesFromContent(
        quill.current.getContents()
      )
    )

    handleChange(quill.current.root.innerHTML)
  }

  const handleHover = () => {
    setHasHoveredEditable(true)
  }

  useEffect(() => {
    if (isEditMode && !quill.current) {
      // All Quill settings can be found in public/js/quill.custom.js
      // eslint-disable-next-line
      quill.current = new Quill(textElement.current, wysiwygConfig)
      const toolbar = quill.current.getModule('toolbar')
      toolbar.addHandler('color', handleShowColorPicker)
    }
  }, [isEditing])

  return (
    <ClickAwayListener
      handleClickAway={handleCloseAndSave}
      isActive={isEditing}
    >
      <Editable
        value={value}
        getTextRef={textElement}
        handleClick={handleClick}
        isEditing={isEditing}
        isEditMode={isEditMode}
        handleHover={handleHover}
        isHoverActive={!hasHoveredEditable && isHoverActiveOnLoad}
      />
    </ClickAwayListener>
  )
}

EditableContainer.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isHoverActiveOnLoad: PropTypes.bool,
}

EditableContainer.defaultProps = {
  isHoverActiveOnLoad: false,
}

export default EditableContainer

import { useEffect, useRef } from 'react'
import fonts from 'components/Editable/wysiwygConfig/fonts.json'

const addFonts = fontList => {
  if (fontList.length === 0) return

  // load fonts from google
  let googleUrl = 'https://fonts.googleapis.com/css?family='
  googleUrl += fontList
    .map(font => `${font}:400,400i,700,700i`)
    .join('|')
  const sheet = document.createElement('link')
  sheet.rel = 'stylesheet'
  sheet.href = googleUrl
  document.body.appendChild(sheet)

  // add the classes for quill
  const styles = document.createElement('style')
  styles.innerHTML = fontList
    .map(font => {
      const camelCaseFont = font.replace(/[+,-]/g, '')
      const fontWithSpace = font.replace(/[+]/g, ' ')
      return `.ql-font-${camelCaseFont} { font-family: "${fontWithSpace}"; }`
    })
    .join('\n')

  document.head.appendChild(styles)
}

const fontLoader = (
  { isLoaded: isSettingsLoaded, settings, isEditMode },
  defaultFonts,
) => {
  const isScriptAdded = useRef(false)

  // load the fonts
  useEffect(() => {
    // only add the script once
    if (isScriptAdded.current) return
    if (isEditMode) {
      // in edit mode load all of the fonts
      addFonts(fonts)
      isScriptAdded.current = true
    } else if (isSettingsLoaded) {
      // if settings are loaded then only load the fonts used by those settings
      const settingsFonts = JSON.stringify(settings.getSaveObj())
        .split('ql-font-')
        .slice(1)
        .map(chunk => chunk.split('\\')[0])
      // make sure font array is unique
      addFonts(
        Array.from(new Set([...settingsFonts, ...defaultFonts])),
      )
      isScriptAdded.current = true
    }
  }, [isEditMode, isSettingsLoaded, isScriptAdded.current])
}

export default fontLoader

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const ClickAwayListener = props => {
  const { children, handleClickAway, isActive } = props
  const ref = useRef(null)

  const onClickEvent = e => {
    if (ref.current.contains(e.target)) return
    handleClickAway()
  }

  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', onClickEvent, false)
    } else {
      document.removeEventListener('mousedown', onClickEvent, false)
    }
    return () =>
      document.removeEventListener('mousedown', onClickEvent, false)
  }, [isActive])

  return <div ref={ref}>{children}</div>
}

ClickAwayListener.propTypes = {
  handleClickAway: PropTypes.func,
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default ClickAwayListener

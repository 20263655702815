import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import PropTypes from 'prop-types'

import PreviewWarningBanner from 'components/PreviewWarningBanner'
import CloseIcon from 'components/Lightbox/Lightbox/CloseIcon'
import Pages from 'components/Lightbox/Lightbox/Pages'
import useSignupFormType from 'hooks/useSignupFormType'

const useStyles = makeStyles({
  builderOverlay: {
    visibility: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: ({ isEmbed }) => (isEmbed ? 'flex-start' : 'center'),
    marginTop: ({ isPreviewMode }) => (isPreviewMode ? '20px' : '0'),
  },
  builderOverlayVisible: {
    transition: 'all 0.2s ease-in-out',
    visibility: 'visible',
    opacity: 1,
  },
  popupOverlay: {
    background: ({ isBuilder, builderBackgroundColor }) =>
      isBuilder ? builderBackgroundColor : 'rgba(44, 53, 65, 0.6)',
    zIndex: 1000,
    height: ({ isBuilder }) => (isBuilder ? '100%' : '100vh'),
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  embedOverlay: {
    height: '100%',
    width: '100%',
  },
  signupForm: {
    backgroundColor: ({ formBackgroundColor }) => formBackgroundColor,
    visibility: 'hidden',
    opacity: 0,
    display: 'flex',
    position: 'relative',
    fontFamily: 'Montserrat',
    padding: 0,
    '& .ql-editor': {
      boxSizing: 'border-box',
      lineHeight: '1.2',
      outline: 'none',
      padding: 0,
      tabSize: '4',
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      marginTop: ({ isBuilder }) => (!isBuilder ? '8px' : 0),
      '&:first-of-type': {
        marginTop: 0,
      },
      '& .ql-align-center': {
        textAlign: 'center',
      },
      '& p': {
        marginTop: ({ isBuilder }) => (!isBuilder ? '8px' : 0),
        '&:first-of-type': {
          marginTop: 0,
        },
      },
    },
  },
  signupFormPopup: {
    transform: 'scale(0)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  signupFormEmbed: {
    marginTop: ({ isEditMode }) => (isEditMode ? '40px' : '0'),
  },
  signupFormVisible: {
    transition: 'all 0.25s ease-in-out',
    visibility: 'visible',
    opacity: 1,
    transform: 'scale(1)',
    border: ({ isEmbed, isEditMode }) =>
      isEmbed && isEditMode ? '1px dashed #86A0AC' : 'none',
    height: ({ isEmbed, isBuilder }) =>
      isBuilder || !isEmbed ? 'auto' : '100%',
  },
  signupFormHeroImageRight: {
    flexDirection: 'row-reverse',
  },
  signupFormHeroImageTop: {
    flexDirection: 'column',
  },
  heroImage: {
    width: 256,
    objectFit: 'cover',
    border: 'none',
  },
  heroImageTop: {
    width: 'auto',
    height: 120,
  },
  heroImageNone: {
    display: 'none',
  },
  pagesContainer: {
    width: '100%',
    display: 'flex',
  },
})

const BACKGROUND_COLORS = {
  popup: '#3F5059',
  lightbox: '#3F5059',
  embed: '#EDEDED',
}

function Lightbox(props) {
  const {
    settings,
    page,
    isEditMode,
    isPreviewMode,
    handleClose,
    getRef,
    handleContentUpdate,
    formState,
    isErrorScreen,
  } = props

  const [signupFormVisible, setSignupFormVisible] = useState(false)
  const { formType, isPopup, isEmbed } = useSignupFormType(settings.settings)

  const isBuilder = isEditMode || isPreviewMode
  const isMobile = settings.currentViewMode === 'mobile'

  useEffect(() => {
    const formStateReady = formState.length > 0
    // Used for smoother fadeIn effect and scale-up for pop-ups
    if (formStateReady) {
      setTimeout(() => setSignupFormVisible(true), 300)
    }
  }, [formState])

  const { themeColors } = settings.settings
  const builderBackgroundColor = BACKGROUND_COLORS[formType]
  let formBackgroundColor = themeColors.background.desktop
  if (isMobile && themeColors.background.mobile) {
    formBackgroundColor = themeColors.background.mobile
  }

  const css = useStyles({
    isEmbed,
    isEditMode,
    isPreviewMode,
    isBuilder,
    formBackgroundColor,
    builderBackgroundColor,
  })

  const heroImgPosition = settings.heroImage.position
  const heroImgTop = isPopup && heroImgPosition === 'top'
  const heroImgRight = isPopup && heroImgPosition === 'right'
  const heroImageNone = isEmbed || isMobile || heroImgPosition === 'none'

  return (
    <div
      className={cn({
        [css.builderOverlay]: isBuilder,
        [css.builderOverlayVisible]: signupFormVisible,
        [css.popupOverlay]: isPopup && signupFormVisible,
        [css.embedOverlay]: isEmbed && signupFormVisible,
      })}
      ref={getRef}
    >
      {isPreviewMode && <PreviewWarningBanner formType={formType} />}

      <div
        className={cn({
          [css.signupForm]: true,
          [css.signupFormVisible]: signupFormVisible,
          [css.signupFormPopup]: isPopup,
          [css.signupFormEmbed]: isEmbed,
          [css.signupFormHeroImageTop]: heroImgTop,
          [css.signupFormHeroImageRight]: heroImgRight,
        })}
      >
        {isPopup && (
          <CloseIcon
            handleClose={handleClose}
            iconFill={settings.themeColors.closeIcon}
          />
        )}

        <img
          className={cn({
            [css.heroImage]: true,
            [css.heroImageTop]: heroImgTop,
            [css.heroImageNone]: heroImageNone,
          })}
          src={
            settings.pages[page].heroImage.url ||
            'https://dojo-asset.s3.us-west-2.amazonaws.com/signup-form-popup-hero-image-placeholder.svg'
          }
        />

        <div className={css.pagesContainer}>
          <Pages
            page={page}
            settings={settings}
            formState={formState}
            handleClose={handleClose}
            handleContentUpdate={handleContentUpdate}
            isErrorScreen={isErrorScreen}
          />
        </div>
      </div>
    </div>
  )
}

Lightbox.propTypes = {
  settings: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPreviewMode: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  getRef: PropTypes.object.isRequired,
  handleContentUpdate: PropTypes.func.isRequired,
  formState: PropTypes.array.isRequired,
  isErrorScreen: PropTypes.bool.isRequired,
}

export default Lightbox

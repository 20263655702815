import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const Dropdown = function (props) {
  const { onBlur, placeholder, options, inputProps, placeholderClass, handleUpdate } = props
  const [selected, setSelected] = useState('')

  const handleChange = event => {
    handleUpdate(event.target.value)
    setSelected(event.target.value)
  }

  return (
    <FormControl variant="outlined" fullWidth={true}>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={selected}
        onChange={handleChange}
        displayEmpty
        onBlur={onBlur}
        inputProps={inputProps}
      >
        <MenuItem value="" disabled>
          <span className={placeholderClass}>{placeholder}</span>
        </MenuItem>
        {options.map(([property, label]) => (
          <MenuItem key={property} value={property}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

Dropdown.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  inputProps: PropTypes.object,
  placeholderClass: PropTypes.string,
  handleUpdate: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
}

Dropdown.defaultProps = {
  inputProps: {},
  placeholderClass: '',
  onBlur: null,
}

export default Dropdown

const useSignupFormMode = () => {
  const url = new URL(document.location.href)
  const params = new URLSearchParams(url.search)
  const isFromDojoMojo = params.get('fromDojoMojo') === 'true'
  const isEditMode = params.get('mode') === 'edit'
  const isPreviewMode = params.has('preview')
  const isDeliverMode = params.has('deliver')
  const isInitializing = !isEditMode && !isPreviewMode && !isDeliverMode

  return {
    params,
    isInitializing,
    isEditMode,
    isPreviewMode,
    isDeliverMode,
    isFromDojoMojo,
  }
}

export default useSignupFormMode

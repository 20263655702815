import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  legalLanguage: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 10,
    lineHeight: '14px',
    fontWeight: 400,
    marginBottom: 16,
    textAlign: 'left',
    color: ({ settings }) => settings.themeColors.legalText,
    '& a': {
      fontSize: 10,
      color: ({ settings }) => settings.themeColors.legalLinks,
      textDecoration: 'none',
    },
  },
})

const LegalLanguage = ({ settings, pageIndex }) => {
  const css = useStyles({ settings })
  const currentPage = settings.settingsPages[pageIndex]
  const hasPhoneField = currentPage.inputs.some(input => input.label === 'Phone')
  
  // show the legal language only if the phone field is present
  return hasPhoneField ? (
    <div className={css.legalLanguage}>
      By signing up, you agree to receive recurring automated
      marketing text messages from {settings.brandName} at the mobile
      number provided at sign up. Consent is not a condition of any
      purchase. Msg & data rates may apply. View{' '}
      <a href={settings.termsUrl} target="_blank" rel="noreferrer">
        Terms
      </a>{' '}
      &{' '}
      <a href={settings.privacyUrl} target="_blank" rel="noreferrer">
        Privacy
      </a>
      .
    </div>
  ) : null
}

LegalLanguage.propTypes = {
  settings: PropTypes.object.isRequired,
  pageIndex: PropTypes.number.isRequired,
}

export default LegalLanguage
